<!--
 * @Auth: linjituan
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan
 * @LastEditTime: 2022-06-27 10:45:15
-->
<template>
  <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <a-button type="primary" @click="toHome">
        Back Home
      </a-button>
    </template>
  </a-result>
</template>

<script>
import { Result } from 'ant-design-vue'
export default {
  name: 'Exception404',
  components: {
    AResult: Result
  },
  methods: {
    toHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
